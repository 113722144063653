import Layout from "../../containers/Layout";
import { Grid } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import CardDetail from "../commons/CardDetail/CardDetail";

const Materials = ({ materiales }) => {
  const { type } = useParams();
  const materialesFiltrados = materiales.filter(
    (material) => material.tipo === type
  );

  return (
    <Layout pt={16}>
      <Grid
        templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
        gap={6}
        justifyContent="space-around"
      >
        {type
          ? materialesFiltrados.map((material) => (
              <CardDetail material={material} key={material._id} />
            ))
          : materiales.map((material) => (
              <CardDetail material={material} key={material._id} />
            ))}
      </Grid>
    </Layout>
  );
};

export default Materials;
