import { useState, useEffect, useContext } from "react";

import Main from "./components/Main/Main";

import Navbar from "./components/Navbar/Navbar";

import axios from "axios";

import { Route, Routes } from "react-router-dom";

import Materials from "./components/Materials/Materials";

import Material from "./components/Material/Material";

import Footer from "./components/Footer/Footer";

import Shop from "./components/Shop/Shop";

import { Flex } from "@chakra-ui/react";

import Login from "./components/Login/Login";

import WelcomeEmail from "./components/WelcomeEmail/WelcomeEmail";

import GuidePronouncing from "./components/GuidePronouncing/GuidePronouncing";

import ProfessionalJourneyEmail from "./components/ProfessionalJourneyEmail/ProfessionalJourneyEmail";

import LearnMore from "./components/LearnMore/LearnMore";

import GetStarted from "./components/GetStarted/GestStarted";

import BookLesson from "./components/BookLesson/BookLesson";

import Subscribe from "./components/Subscribe/Subscribe";

import { UserContext } from "./contexts/UserContext";

import SuccessPaymentPage from "./components/SuccessPaymentPage/SuccessPaymentPage";
import SuccessSubscriptionPage from "./components/SuccessSubscriptionPage/SuccessSubscriptionPage";
import Success from "./components/Success/Success";
import Cancel from "./components/Cancel/Cancel";
import Store from "./components/Store/Store";

function App() {
  const [materiales, setMateriales] = useState([]);

  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    axios

      .get("/api/materials")

      .then((res) => {
        setMateriales(res.data);
      })

      .catch((err) => {
        console.log(err);
      });

    //time out logout code

    const iat = user?.iat;

    const exp = user?.exp;

    const now = Date.now() / 1000;

    if (iat && exp && now > exp) {
      setUser({});
    }
  }, []);

  return (
    <Flex direction="column">
      <Navbar materiales={materiales} />
      <Routes>
        <Route path="/" element={<Main materiales={materiales} />} />

        <Route
          path="/materiales"
          element={<Materials materiales={materiales} />}
        />

        <Route
          path="/materiales/:type"
          element={<Materials materiales={materiales} />}
        />

        <Route
          path="/materiales/:type/:nombre"
          element={<Material materiales={materiales} />}
        />

        <Route
          path="/professional-journey-email"
          element={<ProfessionalJourneyEmail />}
        />

        <Route path="/shop" element={<Shop />} />

        <Route path="/login" element={<Login />} />

        <Route path="/welcome" element={<WelcomeEmail />} />

        <Route path="/guide-Pronouncing" element={<GuidePronouncing />} />

        <Route path="/learn-More" element={<LearnMore />} />

        <Route path="/get-Started" element={<GetStarted />} />

        <Route path="/book-a-Lesson" element={<BookLesson />} />

        <Route path="/subscribe" element={<Subscribe />} />

        <Route path="/store" element={<Store />} />

        <Route path="/success" element={<Success />} />

        <Route path="/cancel" element={<Cancel />} />

        {/* <Route path="/success" element={<SuccessPaymentPage />} /> */}

        <Route
          path="/success-subscription"
          element={<SuccessSubscriptionPage />}
        />
      </Routes>

      <Footer />
    </Flex>
  );
}

export default App;
