import {
  Flex,
  Heading,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import Layout from "../../containers/Layout";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";

const ContactCard = () => {
  const { colors } = useTheme();

  return (
    <Layout>
      <Flex direction={{ base: "column", md: "row" }}>
        <Flex direction={"column"} justifyContent={"center"}>
          <Flex
            p={{ base: 0, md: "3em" }}
            direction={"column"}
            alignItems={{ base: "center", md: "flex-start" }}
            borderWidth={{ base: "0", md: "1px 0 1px 1px" }}
            borderColor={useColorModeValue(
              colors.light.border,
              colors.dark.border
            )}
            bg={{
              base: "transparent",
              md: useColorModeValue(
                colors.light.bgSecondary,
                colors.dark.bgSecondary
              ),
            }}
            borderRadius={"2em 0 0 2em "}
          >
            <Text color="red" pb={{ base: "0.5em", md: "1em" }}>
              SOCIAL MEDIA
            </Text>
            <Heading fontSize="3xl" pb="0.5em">
              {/* Follow for more. */}
            </Heading>
            <Text pb={"2em"} textAlign={{ base: "center", md: "left" }}>
              Enjoying the content? There's plenty more waiting for you right
              here! 🚀 Your support means the world and helps keep the
              creativity flowing—so don’t hesitate to reach out, share your
              thoughts, or drop a comment. I read every single one. Nos vemos!
              😎
            </Text>
            <Flex display={{ base: "none", md: "block" }}>
              <Link
                href="https://www.youtube.com/channel/UCbx58EYkaC1yJBxNuOvhDOg"
                isExternal
              >
                <Icon
                  as={FaYoutube}
                  boxSize={10}
                  border="1px solid grey" // Agregar borde rojo al icono
                  p={2} // Añadir espaciado para el borde
                  borderRadius="full" // Mantener borde circular
                  mr={2}
                  target="_blank"
                  rel="noopener"
                />
              </Link>
              <Link href="https://twitter.com/spanishwithalex" isExternal>
                <Icon
                  as={FaTwitter}
                  boxSize={10}
                  border="1px solid grey" // Agregar borde rojo al icono
                  p={2} // Añadir espaciado para el borde
                  borderRadius="full" // Mantener borde circular
                  mr={2}
                  target="_blank"
                  rel="noopener"
                />
              </Link>
              <Link href="https://www.facebook.com/spanishwithalex" isExternal>
                <Icon
                  as={FaFacebook}
                  boxSize={10}
                  border="1px solid grey" // Agregar borde rojo al icono
                  p={2} // Añadir espaciado para el borde
                  borderRadius="full" // Mantener borde circular
                  mr={2}
                  target="_blank"
                  rel="noopener"
                />
              </Link>
              <Link href="https://www.instagram.com/spanishwithalex" isExternal>
                <Icon
                  as={FaInstagram}
                  boxSize={10}
                  border="1px solid grey" // Agregar borde rojo al icono
                  p={2} // Añadir espaciado para el borde
                  borderRadius="full" // Mantener borde circular
                  target="_blank"
                  rel="noopener"
                />
              </Link>
            </Flex>
          </Flex>
        </Flex>
        <Image
          src="/images/contact_img.png"
          alt="Contact Image"
          width={{ base: "80%", md: "50%", lg: "50%", xl: "auto" }} // Ajusta el ancho de la imagen para evitar que se deforme
          mx="auto" // Centra la imagen en el eje X
          my={{ base: 5, md: 0 }} // Agrega margen vertical en dispositivos móviles
        />
        <Flex
          justifyContent="center"
          pt="2em"
          display={{ base: "block", md: "none" }}
        >
          <Link
            href="https://www.youtube.com/channel/UCbx58EYkaC1yJBxNuOvhDOg"
            isExternal
          >
            <Icon
              as={FaYoutube}
              boxSize={10}
              border="1px solid grey" // Agregar borde rojo al icono
              p={2} // Añadir espaciado para el borde
              borderRadius="full" // Mantener borde circular
              mr={2}
            />
          </Link>
          <Link href="https://twitter.com/spanishwithalex" isExternal>
            <Icon
              as={FaTwitter}
              boxSize={10}
              border="1px solid grey" // Agregar borde rojo al icono
              p={2} // Añadir espaciado para el borde
              borderRadius="full" // Mantener borde circular
              mr={2}
            />
          </Link>
          <Link href="https://www.facebook.com/spanishwithalex" isExternal>
            <Icon
              as={FaFacebook}
              boxSize={10}
              border="1px solid grey" // Agregar borde rojo al icono
              p={2} // Añadir espaciado para el borde
              borderRadius="full" // Mantener borde circular
              mr={2}
            />
          </Link>
          <Link href="https://www.instagram.com/spanishwithalex" isExternal>
            <Icon
              as={FaInstagram}
              boxSize={10}
              border="1px solid grey" // Agregar borde rojo al icono
              p={2} // Añadir espaciado para el borde
              borderRadius="full" // Mantener borde circular
            />
          </Link>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default ContactCard;
