function Cancel() {
  return (
    <div>
      <h1 className="mt-3">Purchase cancelled!</h1>
      <a href="/" className="btn btn-danger mt-2">
        Back to home
      </a>
    </div>
  );
}

export default Cancel;