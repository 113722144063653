import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import Layout from "../../containers/Layout";
import HeadingSection from "../commons/HeadingSection/HeadingSection";
import IconSelector from "../IconSelector/IconSelector.jsx";

// Define los tipos que se consideran imágenes
const imageTypes = ["redDot", "redDotGafasSonrisa"]; // Agrega más tipos de imágenes aquí

const BoxInfoTemplate = ({
  title,
  subTitle,
  mainText,
  iconType,
  showQuotes = true,
  showShadow = true,
  borderColor = "red.500",
}) => {
  const renderText = () => {
    return showQuotes ? `“${mainText}”` : mainText;
  };

  // Determina si el iconType es una imagen
  const isImageType = imageTypes.includes(iconType);

  return (
    <Layout>
      <HeadingSection title={title} sub={subTitle} />
      <Flex
        mb={12}
        flexDirection={{ base: "column", md: "row" }}
        borderWidth="2px"
        borderRadius="lg"
        overflow="hidden"
        justifyContent="space-around"
        alignItems="center"
        p="8"
        borderColor={borderColor}
        boxShadow={showShadow ? "2xl" : "none"}
      >
        {/* Usamos el IconSelector para renderizar el icono dinámicamente */}
        <Flex direction="column" alignItems={"center"} mb={{ base: 6, md: 0 }}>
          <IconSelector iconType={iconType} />
        </Flex>

        {/* Texto principal con el cuadro de diálogo tipo cómic */}
        <Flex
          direction="column"
          alignItems={"center"}
          mx={8}
          position="relative"
        >
          {isImageType && (
            <Box
              bg="#F9F9F6"
              border="2px solid red"
              borderRadius="md"
              p={4}
              position="relative"
              _before={{
                content: '""',
                position: "absolute",
                top: { base: "0px", md: "50%" }, // En modo móvil, coloca el triángulo en la parte superior del cuadro
                left: { base: "50%", md: "-20px" }, // En modo móvil, centra horizontalmente el triángulo
                transform: {
                  base: "translate(-50%, -100%)", // Ajuste de posición para el triángulo en modo móvil
                  md: "translateY(-50%)", // En modo escritorio, se mantiene centrado verticalmente
                },
                borderWidth: "10px",
                borderStyle: "solid",
                borderColor: {
                  base: "transparent transparent red transparent", // En modo móvil, el borde visible es el inferior
                  md: "transparent red transparent transparent", // En modo escritorio, el borde visible es el derecho
                },
              }}
            >
              <Text
                fontSize="2xl"
                fontStyle={showQuotes ? "italic" : "normal"}
                textAlign="center"
                color="black"
              >
                {renderText()}
              </Text>
            </Box>
          )}
          {/* Texto siempre visible, incluso cuando no se usa el cuadro de diálogo tipo cómic */}
          {!isImageType && (
            <Text
              fontSize="2xl"
              fontStyle={showQuotes ? "italic" : "normal"}
              textAlign="center"
              mx={4} // Ajusta el margen horizontal si es necesario
            >
              {renderText()}
            </Text>
          )}
        </Flex>
      </Flex>
    </Layout>
  );
};

export default BoxInfoTemplate;
