import { Link } from "@chakra-ui/react";
import Layout from "../../containers/Layout";
import VideoTemplate from "../VideoTemplate/VideoTemplate";

const mainTextContent = `
I have always been interested in the creative and dynamic aspects of creating materials. I have worked as a teacher and now as a content creator. I especially love the pedagogy of learning. I am passionate about learning processes. I have specialized in conversational Spanish to assist beginners or advanced Spanish speakers who want to take the next step and gain confidence in communication.Nos vemos!
 
`;

// Componente CustomLink sin el prop 'isExternal' para abrir los enlaces en la misma página
const CustomLink = ({ href, children }) => (
  <Link href={href} color="blue.500">
    {children}
  </Link>
);

const LearnMore = () => {
  return (
    <Layout mt={10}>
      <VideoTemplate
        imageUrl="/images/miniatures/mas.jpg"
        imageAlt="Descripción alternativa de la imagen"
        mainTitle="Learning is the only way"
        subTitle=""
        mainText={mainTextContent}
        linkPath="/ruta-nueva"
        showButton={false}
      />

      <CustomLink href="https://www.italki.com/en/teacher/6018279">
        Video about me here.
      </CustomLink>
    </Layout>
  );
};

export default LearnMore;
