import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext();
function getLocalStorage(key, initialValue) {
  try {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : initialValue;
  } catch (e) {
    return initialValue;
  }
}

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(getLocalStorage("userData", {}));
  useEffect(() => {
    localStorage.setItem("userData", JSON.stringify(user));
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
