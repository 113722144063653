import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import Layout from "../../containers/Layout";
import HeadingSection from "../commons/HeadingSection/HeadingSection";
import CustomButton from "../commons/CustomButton/CustomButton";
import { FaDiscord, FaHeart, FaTelegram } from "react-icons/fa";
import { Link } from "react-router-dom";

const HorizontalCardIlustrations = () => {
  return (
    <Layout>
      <Flex
        mb="8"
        direction={"column"}
        alignItems={"center"}
        textAlign={"center"}
      >
        <HeadingSection
          title="COMMUNICATION CHANNELS"
          sub="A modern Academy with modern communication!"
        />
      </Flex>
      <Flex
        flexDirection={{ base: "column", md: "row" }}
        justifyContent={{ base: "center", md: "space-between" }}
      >
        <Flex
          width={{ base: "100%", md: "30%" }}
          mb={12}
          direction={"column"}
          alignItems={"center"}
          textAlign={"center"}
          gap={4}
        >
          <Image src={"/images/Illustration_1.svg"} alt="" width={224} />
          <Heading fontSize="2xl">Our Web</Heading>
          <FaHeart />
          <Text>
            You will find here exclusive content, curated courses, and
            personalized progress tracking that will provide you with the best
            educational experience.
          </Text>
        </Flex>
        <Flex
          width={{ base: "100%", md: "30%" }}
          mb={12}
          direction={"column"}
          alignItems={"center"}
          textAlign={"center"}
          gap={4}
        >
          <Image src={"/images/Illustration_2.svg"} alt="" width={224} />
          <Heading fontSize="2xl">Discord</Heading>
          <FaDiscord />
          <Text>
            Discord is a communication platform. The channels in Discord
            provides open talkrooms to connect with a vibrant community of
            Spanish learners.
          </Text>
        </Flex>
        <Flex
          width={{ base: "100%", md: "30%" }}
          direction={"column"}
          alignItems={"center"}
          textAlign={"center"}
          gap={4}
        >
          <Image src={"/images/Illustration_3.svg"} alt="" width={224} />
          <Heading fontSize="2xl">Telegram</Heading>
          <FaTelegram />
          <Text>
            Stay connected on the go, receive important updates. Whether you are
            at home or exploring the world, Telegram ensures you get the latest
            beat on your phone.
          </Text>
        </Flex>
      </Flex>
      <Flex
        pt={{ base: "8", md: "0" }}
        direction={"column"}
        alignItems={"center"}
      >
        <Link to="/Get-Started">
          <CustomButton label="Get Started" />
        </Link>
      </Flex>
    </Layout>
  );
};

export default HorizontalCardIlustrations;
