import { Flex } from "@chakra-ui/react";
import "@fontsource/comic-mono/latin-400.css";
import "@fontsource/courier-prime/index.css";
import "@fontsource/courier-prime/latin-700-italic.css";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import Layout from "../../containers/Layout";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../contexts/UserContext";
import DualTextVideoTemplate from "../DualTextVideoTemplate/DualTextVideoTemplate";
import BoxInfoTemplate from "../BoxInfoTEmplate/BoxInfoTemplate";

const Login = () => {
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const postData = async (data) => {
    try {
      const response = await axios.post("/api/login", data);
      const decoded = jwtDecode(response.data);
      setUser(decoded);
      console.log(decoded);
      navigate("/success-subscription");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Layout mt="120">
      <Flex alignItems="center" justifyContent="center">
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            const decoded = jwtDecode(credentialResponse?.credential);
            const { email, name, picture } = decoded;
            postData({ email, name, picture });
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      </Flex>

      {/* <Container centerContent>
        <Box my={5} width="100%" height="0" paddingBottom="56.25%" position="relative">
          <iframe title="video querer presente" 

            src="https://iframe.mediadelivery.net/embed/245875/2d63ef8e-81f5-4043-bca7-3575f032dd14?autoplay=true&loop=false&muted=false&preload=true&responsive=true"
            loading="lazy"
            style={{
              border: 0,
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>

        <VStack spacing={4} align="stretch">
          <Text fontFamily="Comic Mono">
            This was supposed to be the login page, but for now, you will find a video featuring the logo.
          </Text>

          <Text fontFamily="Courier Prime" fontStyle="italic" fontWeight="700">
            Links are for testing
          </Text>

          <Heading size="md" my={5}>Recursos útiles:</Heading>
          <CustomLink href="https://www.italki.com/en/teacher/6018279"> My profile in Italki </CustomLink>

          <CustomLink href="http://spanishwithalex.com/welcome">spanishwithalex.com/welcome</CustomLink>
          <CustomLink href="http://spanishwithalex.com/Professional-Journey-Newsletter">spanishwithalex.com/Professional-Journey-Newsletter</CustomLink>
          <CustomLink href="http://spanishwithalex.com/guide-pronouncing">spanishwithalex.com/guide-pronunciation</CustomLink>
        </VStack>
      </Container> */}
    </Layout>
  );
};

export default Login;
