import { Card, CardBody, CardFooter, Flex, Text } from "@chakra-ui/react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VideoTemplate from "../VideoTemplate/VideoTemplate";
import { UserContext } from "../../contexts/UserContext";
import BoxInfoTemplate from "../BoxInfoTEmplate/BoxInfoTemplate";

const Material = () => {
  const { type, nombre } = useParams();
  const [material, setMaterial] = useState({});
  const { user } = useContext(UserContext);

  useEffect(() => {
    axios
      .get(`/api/materials/${type}/${nombre}`)
      .then((res) => {
        console.log(res);

        setMaterial(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Card>
      <CardBody pt={16}>
        {!material.premium || (material.premium && user?.name) ? (
          <>
            {" "}
            <Flex alignItems="center" justifyContent="center">
              {/* <Flex flex="1" justifyContent="center">
      {material.tipo === "podcast" && <PiApplePodcastsLogo color="lightblue" fontSize={"5.5em"} />}
      {material.tipo === "video" && <IoPlayCircle color="red" fontSize={"5.5em"} />}
      {material.tipo === "gramaticas" && <PiTextAlignLeftFill color="yellow" fontSize={"5.5em"} />}
    </Flex> */}
              <VideoTemplate
                videoUrl={material.urlContenido}
                // imageUrl={material.titulo}
                // imageAlt="Descripción alternativa de la imagen"
                mainTitle={material.titulo}
                subTitle=""
                mainText={material.descripcion}
                buttonLabel="Join Telegram"
                linkPath="https://t.me/+S78OquqBTZdmNGNk"
                showButton={true}
              />
            </Flex>
          </>
        ) : (
          // <BoxInfoTemplate
          //   title="Login need it"
          //   mainText="Login to enjoy this content. It´s for free "
          //   iconType="alarm"
          //   borderColor="red"
          //   showQuotes={false}
          // />
          <BoxInfoTemplate
            title="Login need it"
            mainText="Login to enjoy this content. It´s free.🎉"
            iconType="redDot"
            borderColor="orange"
            showQuotes={true}
          />
        )}
      </CardBody>
      <CardFooter
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      ></CardFooter>{" "}
    </Card>
  );
};

export default Material;
