import { Text } from "@chakra-ui/react";
import Layout from "../../containers/Layout";

const Subscribe = (  ) => {
    return (
        <Layout mt={136}>
           <Text>
Enter your email, subscribe and get notify when the website is release.        
 </Text>
        </Layout>
    );
}

export default Subscribe;
