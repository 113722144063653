import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import Layout from "../../containers/Layout";
import CustomButton from "../commons/CustomButton/CustomButton";
import { Link } from "react-router-dom";

const ConversationZoom = () => {
  return (
    <Layout>
      <Flex
        direction={{ base: "column", md: "row-reverse" }}
        alignItems={"center"}
      >
        <Text color="red" mb={2} display={{ base: "block", md: "none" }}>
          START TALKING TODAY
        </Text>
        <Flex justifyContent={"center"}>
          <img src="images\calander_img1.png" alt="Alex talking with student" />
        </Flex>
        <Flex direction={"column"}>
          <Text
            color="red"
            mb={2}
            display={{ base: "none", md: "block" }}
            justifyContent={"center"}
          >
            LESSONS WITH ME
          </Text>
          <Heading fontSize="2xl" mb={4}></Heading>
          <Text mb={4}>
            Hola estudiantes. ¿Quieres tener clase conmigo? Si!? Puedes darle al
            botón para reservar una clase y aprender español. Te estoy
            esperando.
          </Text>
          <Box mt={6}>
            <Link to="/Book-a-Lesson">
              <CustomButton label="Book" />
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default ConversationZoom;
