import {
  Divider,
  Flex,
  Image,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import { useEffect, useRef, useState } from "react";
import Layout from "../../containers/Layout";
import "./Header.css";

const Header = ({ materiales }) => {
  const { colors } = useTheme();
  const [width, setWidth] = useState(0);
  const targetRef = useRef();
  const { colorMode } = useColorMode();
  const [lessons, setLessons] = useState(7400); // Starting with 7.4k lessons

  // Function to generate a random integer between min and max (inclusive)
  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  useEffect(() => {
    if (targetRef.current) {
      setWidth(targetRef.current.offsetWidth);
    }
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Load the initial lessons count from localStorage or start with 7400
    const storedLessons = localStorage.getItem("lessonsCount");
    if (storedLessons) {
      setLessons(parseInt(storedLessons, 10));
    }

    // Increment the lessons count by a random number between 1 and 3 every day
    const today = new Date().toDateString();
    const lastUpdated = localStorage.getItem("lastUpdated");
    if (lastUpdated !== today) {
      const increment = getRandomInt(1, 3); // Get a random increment value
      const updatedLessons = parseInt(storedLessons || lessons, 10) + increment;
      setLessons(updatedLessons);
      localStorage.setItem("lessonsCount", updatedLessons.toString());
      localStorage.setItem("lastUpdated", today);
    }

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const bannerImageUrl =
    colorMode === "light"
      ? "./images/banner_img2Gris.png"
      : "./images/banner_img2.png";

  return (
    <Layout mt={16}>
      <Flex justifyContent={"center"} ref={targetRef}>
        {width >= 768 ? (
          <Image
            src={bannerImageUrl}
            alt="Banner Image Desktop"
            boxSize="800px"
            height="auto"
            objectFit="contain"
            className="fade-in"
          />
        ) : (
          <Image
            src="./images/banner_img3.png"
            alt="Banner Image Mobile"
            boxSize="350px"
            height="auto"
            objectFit="contain"
            className="fade-in"
          />
        )}
      </Flex>
      <Flex
        direction={{ base: "column", md: "row" }}
        p={8}
        justifyContent={"space-around"}
        alignItems={"center"}
        borderColor={useColorModeValue(colors.light.border, colors.dark.border)}
        borderWidth="1px"
        borderRadius={18}
        boxShadow="2xl"
        bg={useColorModeValue(
          colors.light.bgSecondary,
          colors.dark.bgSecondary
        )}
        zIndex={1}
        position="relative"
        top="-20px"
      >
        <Flex
          width={{ base: "100%", md: "60%" }}
          justifyContent={"space-around"}
          gap={{ base: 6 }}
        >
          <Flex direction={"column"}>
            <Text fontSize={{ base: "1xl", md: "1xl" }}>Live</Text>
            <Text fontSize={{ base: "4xl", md: "6xl" }}>10</Text>
          </Flex>
          <Flex direction={"column"}>
            <Text fontSize={{ base: "1xl", md: "1xl" }}>Lessons</Text>
            <Text fontSize={{ base: "4xl", md: "6xl" }}>
              {lessons.toLocaleString()}
            </Text>
          </Flex>
          <Flex direction={"column"}>
            <Text fontSize={{ base: "1xl", md: "1xl" }}>Materials</Text>
            <Text fontSize={{ base: "4xl", md: "6xl" }}>
              {materiales.length}
            </Text>
          </Flex>
        </Flex>
        <Divider
          display={{ base: "block", md: "none" }}
          borderColor={useColorModeValue(
            colors.light.border,
            colors.dark.border
          )}
          borderWidth="1px"
          height="auto"
          mt={2}
          mb={3}
        />
        <Flex
          direction={"column"}
          gap={{ base: 2, md: 5 }}
          alignSelf={{ md: "start" }}
        >
          <Text fontSize="1xl"> ⭐⭐⭐⭐⭐ 5/5 </Text>
          <Text fontSize="1xl">
            Students give Alex an <br /> average rating of 5 stars.
          </Text>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default Header;
