import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import Layout from "../../containers/Layout";
import HeadingSection from "../commons/HeadingSection/HeadingSection";
import CustomButton from "../commons/CustomButton/CustomButton";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const VideoTemplate = ({
  videoUrl,
  imageUrl,
  imageAlt,
  mainTitle,
  subTitle,
  mainText,
  linkPath,
  showButton = true,
  buttonLabel = "Learn More", // Valor por defecto es "Learn More"
}) => {
  useEffect(() => {
    // Asegúrate de que la página se desplaza hacia arriba al cargar el componente
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Flex
        direction={{ base: "column", xl: "row" }}
        align={{ base: "center", xl: "flex-start" }}
        justify={{ base: "center", xl: "space-between" }}
      >
        <Center w={{ base: "100%", xl: "50%" }} mb={{ base: 5, xl: 0 }}>
          <Image src={imageUrl} alt={imageAlt} mx={{ base: "auto", lg: "0" }} />
          <Box
            my={5}
            width="100%"
            height="0"
            paddingBottom="76.25%"
            position="relative"
          >
            <iframe
              title="video"
              src={videoUrl}
              loading="lazy"
              scrolling="no"
              style={{
                border: 0,
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Box>
        </Center>
        <Flex
          direction={"column"}
          pl={{ base: 2, md: 20, lg: 20 }}
          pr={{ base: 2, md: 0 }}
          pt={{ base: 8, md: 0 }}
          textAlign={"left"}
          w={{ xl: "50%" }}
        >
          <HeadingSection title={mainTitle} sub={subTitle} />
          <Text mb={6}>{mainText}</Text>
          {showButton && (
            <Box textAlign={{ base: "center", xl: "center" }}>
              <Link to={linkPath}>
                <CustomButton label={buttonLabel} />
              </Link>
            </Box>
          )}
        </Flex>
      </Flex>
    </Layout>
  );
};

export default VideoTemplate;
