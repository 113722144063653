import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  Text,
  useColorModeValue,
  useMediaQuery,
} from "@chakra-ui/react";
import { useTheme } from "@emotion/react";
import { FaCrown } from "react-icons/fa";

import { FaRegCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import Layout from "../../containers/Layout";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./CarouselMateriales.css";

const arrowStyles = {
  position: "absolute",
  zIndex: 2,
  top: "calc(50% - 15px)",
  width: 30,
  height: 30,
  cursor: "pointer",
};

const MaterialElement = ({ material }) => {
  const { colors } = useTheme();

  return (
    <Container my="12" maxW={{ base: "container.sm", md: "6xl" }}>
      <Box
        maxW="100%"
        borderRadius="lg"
        overflow="hidden"
        boxShadow="lg"
        borderWidth="1px"
        borderColor={useColorModeValue(colors.light.border, colors.dark.border)}
      >
        <Image src={material.urlImagen} alt="image" />
        <Box
          borderRadius="lg"
          display="flex"
          alignItems="center"
          justifyContent="center"
          py={4}
        >
          {material.premium && <FaCrown style={{ marginRight: "10px" }} />}
          <Text>{material.titulo}</Text>
        </Box>
      </Box>
    </Container>
  );
};

const CarouselMateriales = ({ materiales }) => {
  const sortedAsc = materiales.sort(
    (objA, objB) => Number(objA.fecha) - Number(objB.fecha)
  );

  const lastMaterials = sortedAsc.reverse().slice(0, 5);

  const [isSmallScreen] = useMediaQuery("(max-width: 768px)");
  const elementsToShow = isSmallScreen ? 1 : 3;

  return (
    <Layout bg={"secondary"}>
      <Flex direction={"column"} alignItems={"center"} textAlign={"center"}>
        {/* <Text pb="4" color="red">
          THE SITE IS UNDER CONSTRUCTION.
        </Text> */}
        <Heading fontSize="3xl">New Content.</Heading>
      </Flex>
      <Carousel
        infiniteLoop
        showStatus={false}
        showArrows={!isSmallScreen}
        showThumbs={false}
        centerMode={!isSmallScreen}
        centerSlidePercentage={100 / elementsToShow}
        emulateTouch={!isSmallScreen}
        swipeScrollTolerance={!isSmallScreen ? 2 : 10}
        showSides={!isSmallScreen}
        selectedItem={0}
        interval={5000}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{
                ...arrowStyles,
                left: 24,
                top: 156,
                borderColor: "#020919",
                borderWidth: "1px",
                borderRadius: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="arrow"
            >
              <ArrowBackIcon color="#020919" />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              style={{
                ...arrowStyles,
                right: 24,
                top: 156,
                borderColor: "#020919",
                borderWidth: "1px",
                borderRadius: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="arrow"
            >
              <ArrowForwardIcon color="#020919" />
            </button>
          )
        }
        renderIndicator={(onClickHandler, isSelected, index, label) => {
          const icon = isSelected ? "●" : "○";
          return (
            <span
              style={{
                marginTop: 30,

                marginLeft: 20,
                color: "red",
                cursor: "pointer",
                fontSize: "20px", // Increase the font size for larger circles
                padding: "10px", // Optional: Add some padding for better clickability
              }}
              onClick={onClickHandler}
              onKeyDown={onClickHandler}
              value={index}
              key={index}
              role="button"
              tabIndex={0}
              aria-label={`${label} ${index + 1}`}
            >
              {icon}
            </span>
          );
        }}
      >
        {lastMaterials.map((material) => {
          return (
            //con el user de context podemos hacer una logica, puedes basarte en la navbar, donde trajimos el user
            //  const { user } = useContext(UserContext);

            <Link
              to={`/materiales/${material.tipo}/${material.urlTitulo}`}
              key={material._id}
            >
              <MaterialElement material={material} />
            </Link>
          );
        })}
      </Carousel>
    </Layout>
  );
};

export default CarouselMateriales;
