import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import App from "./App";
import theme from "./theme/theme";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { UserProvider } from "./contexts/UserContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider>
      <ChakraProvider theme={theme}>
        <BrowserRouter>
          <GoogleOAuthProvider clientId="202328230581-36guloj6rb04hqbvbpbj3lddd6nijt6l.apps.googleusercontent.com">
            <App />
          </GoogleOAuthProvider>
        </BrowserRouter>
      </ChakraProvider>
    </UserProvider>
  </React.StrictMode>
);
